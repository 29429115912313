<template>
  <div class="qrimg">

    <div class="longpic">
      <viewer :images="resData.qrcode">
        <van-image width="100%" height="100%" lazy-load :src="resData.qrcode" @click="clickBigShow(resData.qrcode)" />
      </viewer>
    </div>
    <div class="flowIMG">
      <div class="text">{{ resData.desc }}</div>
      <div class="btns">
        <button type="button" @click="orderList">返回订单列表</button>
      </div>
    </div>

  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref } from "vue";
import API from "@/axios";
import mitts from "@/bus";
import { Toast } from "vant";

import { useRouter, useRoute } from "vue-router";
import wx from 'jweixin-js';//JS-SDK 1.6.0

const $viewerApi = getCurrentInstance().appContext.config.globalProperties.$viewerApi;
const router = useRouter();
const route = useRoute()
const resData = ref([]);
// 需要使用的JS接口列表
const jsApiListArr = [
  "updateTimelineShareData",
  "updateAppMessageShareData",
  "onMenuShareTimeline",
  "onMenuShareAppMessage",
  'checkJsApi',
  'hideMenuItems',
];

const aid = ref("")

onMounted(() => {
  aid.value = route.query.aid
  getwxconfig()
})

//初始化微信sdk
function getwxconfig() {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
      // url: encodeURIComponent("https://saas.zhijierongxing.com"), // 正式上线请替换上面的
    },
  }).then((res) => {
    if (res.data.status == 200) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: jsApiListArr
      });

      wx.checkJsApi({
        jsApiList: jsApiListArr,
        success: function () {
          // console.log('wx.checkJsApi:success',res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        error: function (res) {
          console.log('wx.checkJsApi:error', res)
        }
      });

      wx.error(function (res) {
        console.log('wx.error', res)
        // Toast.fail({
        //   message: '微信JSSDK信息验证失败！'
        // });
      });

      getInfo();
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function getInfo() {
  API({
    url: "/web/activity/qrcode",
    data: {
      aid: aid.value,
    },
    method: "post",
  }).then((res) => {
    mitts.emit("closeLoading");
    if (res.data.status == 200) {
      resData.value = res.data.data;
      loadWxShare()
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

//加载微信分享组件
function loadWxShare() {

  wx.ready(function () {
    wx.hideMenuItems({
      // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录31);
      menuList: [
        'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:favorite', 'menuItem:share:QZone', 'menuItem:openWithSafari'
      ]
    })

    let shareTitle = resData.value.share_title;
    let shareDesc = resData.value.share_desc;
    let shareLink = resData.value.share_url;
    let shareImg = resData.value.share_img;

    //1.4以下用这个分享给朋友
    /*
    wx.onMenuShareAppMessage({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail:function (res){
        console.log('onMenuShareAppMessage.fail',res)
      }
    })
*/
    //1.4以上用这个分享给朋友
    wx.updateAppMessageShareData({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateAppMessageShareData.fail', res)
      }
    })

    //1.4以下用这个分享到朋友圈
    /*
    wx.onMenuShareTimeline({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail:function (res){
        console.log('onMenuShareTimeline.fail',res)
      }
    })
*/
    //1.4以上用这个分享到朋友圈
    wx.updateTimelineShareData({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateTimelineShareData.fail', res)
      }
    })

  });

}

function clickBigShow(item) {
  $viewerApi({ images: [item] })
}

function orderList() {
  router.push({
    path: "/event/event",
    query: { url: encodeURIComponent('/web/activity/orderList') }
  });
}
</script>

<style lang="less" scoped>
.qrimg {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  box-shadow: border-box;
  padding-bottom: 3vh;
}


.longpic {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 10px;

  img {
    width: 100%;
    display: block;
  }
}

.flowIMG {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #dfdfdf;
  padding-bottom: 30px;
}

.text {
  padding: 20px;
  background: #fff;
  font-size: 28px;
  line-height: 32px;
  text-align: justify;
  margin-bottom: 30px;
}

button {
  background: none;
  font-size: 28px;
  height: 66px;
  line-height: 66px;
  border-radius: 10px;
  width: 80%;
  margin: 0 10%;
  border: 3px solid @theme-secondary-color;
  color: @font-white-color;
  background: @theme-secondary-color;
}
</style>